<template>
  <div
  class="wrapper businesses-table h-100 pa-4 pt-0"
  ref="team-list"
  :class="{'pb-0'
  :
  $route.name === 'Sweep Blocks' && $route.query.activeTab === 'sweepBlockDetails'}">
    <v-row class="ma-auto h-100">
      <ag-grid-vue style="width: 100%; height: 100%"
      rowHeight="70"
        class="ag-theme-alpine businesses-list"
        :columnDefs="columnDefs"
        :rowClassRules="rowClassRules"
        :rowData="fetching ? [] : sortedItems"
        @grid-ready="onGridReady"
        @model-updated="onGridUpdated"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="overlayNoRowsTemplate"
        :aria-selected="true"
        :pagination="paginationVal"
        :paginationPageSize="20"
        :gridOptions="gridOptions"
        @rowClicked="onRowClicked"
      >
      </ag-grid-vue>
    </v-row>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import _ from 'lodash';

export default {
  components: {
    AgGridVue,
  },
  props: {
    paginationVal: {
      type: Boolean,
      required: false,
      default: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    fetching: {
      type: Boolean,
      default: true,
    },
    role: {
      type: String,
      default: '',
    },
    refreshKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    sortedItems() {
      return _.sortBy(this.items, ['name']);
    },
  },
  watch: {
    refreshKey() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    },
    headers(newVal) {
      this.columnDefs = this.columnDefaultDefs(newVal);
    },
    search(newVal) {
      this.gridApi.setQuickFilter(newVal);
    },
  },
  data() {
    return ({
      list: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      rowClassRules: null,
      defaultColDef: null,
      overlayLoadingTemplate: null,
      modules: [],
      rowData: null,
      selected: [],
    });
  },
  methods: {
    onRowClicked(row) {
      this.$emit('rowClicked', row);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      if (this.fetching) {
        this.gridApi.showLoadingOverlay();
      }
      this.gridApi.sizeColumnsToFit();
      window.addEventListener('resize', this.resizeListener);
    },
    onGridUpdated(params) {
      this.gridApi = params.api;
      if (this.fetching) {
        this.gridApi.showLoadingOverlay();
      }
      this.gridApi.sizeColumnsToFit();
      if (this.gridApi.getDisplayedRowCount()) {
        this.gridApi.hideOverlay();
      } else if (!this.fetching) {
        this.gridApi.showNoRowsOverlay();
      }
    },
    columnDefaultDefs(headers) {
      return _.map(headers, (item) => {
        if (_.isObject(item)) {
          return item;
        }
        return {
          headerName: item,
          field: item,
          checkboxSelection: false,
          filter: true,
          autoHeight: true,
        };
      });
    },
    resizeListener() {
      this.gridApi.sizeColumnsToFit();
    },
  },
  async beforeMount() {
    this.gridOptions = { enableCellTextSelection: true };
    this.columnDefs = this.columnDefaultDefs(this.headers);

    this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>';
    this.overlayNoRowsTemplate = '<span class="ag-overlay-loading-center">No data to show.</span>';
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeListener);
  },
};
</script>

<style lang="scss">
  .delete-row {
    background-color: #E8E8E8 !important;
  }
  .ag-row {
    cursor: pointer;
  }
  ::v-deep .ag-center-cols-container {
    width: 100% !important;
  }
  .businesses-table {
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 10px;
    .ag-theme-alpine {
      overflow-x: hidden !important;
      font-family: $fontFamily1;
      .ag-row-hover {
        background-color: $neutral9 !important;
      }
    }
    .businesses-list {
      .ag-center-cols-viewport {
        overflow-x: hidden !important;
      }
      .ag-theme-alpine {
        overflow-x: hidden !important;
      .newTagColumn.ag-cell {
        padding-right: 0px !important;
        padding-left: 0px !important;
        left: 12px !important;
      }
    }
    }
  }
</style>
