import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.params.type==='business')?_c('span',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex justify-end card-body w-100"},[_c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","offset-x":"","content-class":"uploaded-content"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"menu-btn",attrs:{"dark":"","icon":"","height":"auto","width":"20"}},on),[_c(VIcon,{attrs:{"color":"black","dense":""}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,1979193081)},[_c(VList,[(_vm.params.type==='business')?_c(VListItem,{staticClass:"pa-0"},[_c(VListItemTitle,[_c(VBtn,{staticClass:"w-100 px-2 py-1",attrs:{"text":"","color":"#FF0000"},on:{"click":function($event){return _vm.openModal(2)}}},[_vm._v(" Remove Affiliation ")])],1)],1):_vm._e()],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }