import { render, staticRenderFns } from "./FilePreview.vue?vue&type=template&id=2a54e4bf&scoped=true"
import script from "./FilePreview.vue?vue&type=script&lang=js"
export * from "./FilePreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a54e4bf",
  null
  
)

export default component.exports