<template>
    <div v-if="preview">
        <slot :filePreview="filePreview"/>
    </div>
    <div v-else>
        <slot />
    </div>
</template>

<script>
export default {
  name: 'FilePreview',
  props: {
    file: {
      default: '',
    },
    image: {
      default: '',
    },
    preview: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return ({
      previewAvailable: false,
      filePreview: '',
    });
  },
  watch: {
    file(prev) {
      if (this.preview) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          this.previewAvailable = true;
          this.filePreview = reader.result;
        }, false);
        if (prev) {
          if (/\.(jpe?g|png|gif)$/i.test(prev.name)) {
            reader.readAsDataURL(prev);
          }
        }
      }
    },
  },
};
</script>

.<style lang="scss" scoped>

</style>
