<template>
  <div class="h-100 bg-white">
    <div class="main-container h-100">
    <v-row class="ma-0 justify-space-between align-center mb-12">
        <PageTitle />
        <v-btn
          class="add-button btn-purple"
          @click="openForm"
        >+ New Organization</v-btn>
      </v-row>
      <v-row class="ma-0 justify-end">
        <BaseInput
            solo
            hide-details
            v-model="search"
            placeholder="Search ..."
            prepend-inner-icon="search"
            class="mt-1 search-box"
            clearable
            @click:clear="search === ''"
          />
      </v-row>
      <div class="listing-block">
        <Loader v-if="loading" />
        <OrganizationsList
          :organizations="agencies"
          :fetching="loading"
          @onModalOpen="openEditModal"
          :search="search"
          @rowClicked="navigate"
        />
      </div>
    </div>
    <v-container
      fluid
      v-if="showOrganizationForm"
    >
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showOrganizationForm"
        scrollable
      >
        <OrganizationForm
          @close-modal="closeForm"
          :isAdmin="true"
          :statusType="getStatusByName(orgType)"
          :organizationObj="selectedOrganization"
          :canArchive="$can('delete', 'project management')"
        />
      </Modal>
    </v-container>
     <template v-if="showConfirm">
      <v-dialog v-model="showConfirm" max-width="700">
        <v-card class="pa-3" flat>
          <div class="d-flex justify-end">
            <v-icon class="pa-2 pb-1 icon-height" @click="showConfirm = false">
              mdi-close
            </v-icon>
            </div>
            <v-card-text class="modal-heading pl-2 pb-1">
              Are you sure you want to delete ?
            </v-card-text>
            <v-row class="ma-0 pa-4 py-3">
              <v-col cols="12" class="d-flex justify-end pa-0">
                  <v-btn text
                    @click="showConfirm = false"
                    class="pa-0"
                    color="primaryGray1"
                  >
                    Cancel
                  </v-btn>
                  <v-btn text
                    type="submit"
                    class="ml-4 pa-0 btn-purple"
                    @click="removeAgency(organizationId)"
                  >
                    Delete
                  </v-btn>
              </v-col>
            </v-row>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Modal from '@/components/common/Modal';
import BaseInput from '@/components/common/BaseInput';
import OrganizationForm from '@/components/organizations/OrganizationForm';
import OrganizationsList from '@/components/organizations/OrganizationsList';
import Loader from '@/components/common/Loader';
import PageTitle from '@/components/common/PageTitle';

export default {
  components: {
    OrganizationForm,
    Modal,
    OrganizationsList,
    BaseInput,
    Loader,
    PageTitle,
  },

  watch: {
    orgType(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.getAgenciesByStatus(newValue);
      }
    },
  },

  computed: {
    ...mapGetters('organizations', ['agencies']),
  },

  methods: {
    ...mapActions('organizations', ['getAgencies', 'deleteAgency']),
    ...mapActions('profile', ['getClientOptions']),
    navigate(row) {
      const { id } = row.data;
      this.$router.push(`/organizations/${id}`);
    },
    getStatusByName(status) {
      switch (status) {
        case 'Inactive':
          return '0';
        case 'Archived':
          return '2';
        default:
          return '1';
      }
    },
    async removeAgency(id) {
      const result = await this.deleteAgency({ status: this.getStatusByName(this.orgType), id });
      if (result.success) {
        this.showConfirm = false;
      }
    },
    async closeForm() {
      this.showOrganizationForm = false;
      this.selectedOrganization = {};
    },
    openForm() {
      this.selectedOrganization = {};
      this.showOrganizationForm = true;
    },
    openEditModal(id, type) {
      if (type === 1) {
        this.showLoader = true;
        this.selectedOrganization = _.cloneDeep(_.find(this.agencies, ['id', id]));
        this.selectedOrganization.businessTypeId = _.get(this.selectedOrganization, 'businessTypeId', null);
        this.selectedOrganization.name = _.get(this.selectedOrganization, 'name');
        this.selectedOrganization.bannerImage = _.get(this.selectedOrganization, 'bannerImage', null);
        this.selectedOrganization.firstName = _.get(this.selectedOrganization.userBusiness, '[0].user.firstName');
        this.selectedOrganization.lastName = _.get(this.selectedOrganization.userBusiness, '[0].user.lastName');
        this.selectedOrganization.email = _.get(this.selectedOrganization.userBusiness, '[0].user.email');
        this.selectedOrganization.status = _.get(this.selectedOrganization, 'status');
        this.showOrganizationForm = true;
        this.showLoader = false;
      } else if (type === 2) {
        this.openDeleteModal(id);
      }
    },
    openDeleteModal(id) {
      this.showConfirm = true;
      this.selectedOrganization = _.cloneDeep(_.find(this.agencies, ['id', id]));
      this.organizationId = this.selectedOrganization.id;
    },
    async getAgenciesByStatus(status) {
      this.loading = true;
      if (status === 'Archived') {
        await this.getAgencies(2);
      } else {
        await this.getAgencies(1);
      }
      this.loading = false;
    },
  },

  async mounted() {
    this.loading = true;
    await this.getAgencies(1);
    await this.getClientOptions();
    this.loading = false;
  },

  data() {
    return {
      showOrganizationForm: false,
      search: '',
      status: '',
      loading: false,
      showLoader: false,
      selectedOrganization: {},
      showConfirm: false,
      organizationId: '',
      statusItems: ['Active', 'Archived'],
      orgType: 'Active',
    };
  },
};
</script>

<style scoped lang="scss">
.organization-filter {
  border: 1px solid #b0b0b0 !important;
  max-width: 150px;
}
.padding-right{
  padding-right:60px !important;
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box ::v-deep {
  .v-input__slot {
    border: 1px solid  #b0b0b0 !important;
    box-shadow: none !important;
    border-radius: 4px !important;
  }
  .v-input__control {
    input {
      max-height: 34px !important;
    };
  }
}
.add-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
.search-box {
  ::v-deep .v-text-field {
    height: 40px;
    font-size: 16px;
  }
}
.modal-heading {
  font-family: $fontFamily1;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: #1b1c1e !important;
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.modal-card {
  position: absolute;
  right: 0px;
  width:500px;
  font-family: $fontFamily1;
  font-size: 14px;
  top: 50px;
  z-index: 10;
  .card-list {
    background-color: #f8f8f8;
  }
}

.listing-block {
  margin-top: 20px;
  height: calc(100vh - 150px);
  overflow: auto;
}
@media (min-width: 600px) {
  .border-right {
    border-right: 1px solid #dddddd;
  }
}
@media (max-width: 600px) {
  .listing-block {
    height: calc(100vh - 165px);
  }
}
</style>
