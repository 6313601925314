<template>
  <span class="d-flex align-center" v-if="params.type==='business'">
    <div class="d-flex justify-end card-body w-100">
      <v-menu bottom left offset-y offset-x content-class="uploaded-content">
        <template v-slot:activator="{ on }">
            <v-btn
              dark
              icon
              height="auto"
              width="20"
              v-on="on"
              class="menu-btn"
            >
              <v-icon color="black" dense>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item v-if="params.role === 'admin' || params.role === 'executive'">
              <v-list-item-title>
                <v-btn text class="w-100" @click="openModal(1)">
                  Edit
                </v-btn>
              </v-list-item-title>
            </v-list-item> -->
            <v-list-item v-if="params.type==='business'" class="pa-0">
              <v-list-item-title>
                <v-btn text class="w-100 px-2 py-1" color="#FF0000" @click="openModal(2)">
                  Remove Affiliation
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
      </v-menu>
    </div>
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  methods: {
    openModal(type) {
      this.params.clicked(this.params, type);
    },
  },
});
</script>

<style lang="scss" scoped>
.v-list-item {
  padding: 10px 15px !important;
  &:nth-child(2) {
    padding-top: 0px !important;
  }
}
    .v-card {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        background-color: $neutral6;
    }
    .uploaded-file-type-image {
        width: 43px;
        height: 56px;
        border-radius: 0 !important;
    }
    .v-card__title {
        font-family: $fontFamily1;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.24px;
        color: $neutral1;
        padding: 10px;
        line-height: 1rem;
    }
    .uploaded-content {
      .v-list-item {
        min-height: 0 !important;
        padding: 0;
      }
    }
    .button-content{
      justify-content: left;
    }
  .v-list {
    border-radius: 0;
    padding: 0;
  }
  .menu {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 5px;
    z-index: 7;
  }
  .menu-btn {
    width: 10px;
    margin-right: 5px;
  }
  .v-menu__content {
    border-radius: 0;
  }
  .v-list-item {
    min-height: auto;
    padding: 0 5px;
    .v-btn {
      height: 30px;
    }
    ::v-deep {
      .v-btn__content {
        font-size: 14px;
        font-family: $fontFamily1;
        justify-content: flex-start;
        width: auto;
        .download-icon {
          padding-right: 5px;
        }
      }
      .v-btn {
        padding: 5px;
      }
    }
  }
</style>
