<template>
  <span class="d-flex align-center">
    <section class="avatars-group pa-3 stacked">
      <div
      v-for="(avatar, i) in getSortedItems"
      :key="`avatar-id-${avatar.id}`"
      class="avatars-group__item"
      :class="i === 4 && 'addOpacity'">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-avatar v-on="on" :class="borderedClass(avatar)">
              <img
                v-if="borderedClass(avatar) === 'border-white'"
                :src="getOptimizedS3ImageURL(profilePic(avatar), { width: 250, height: 250 })"
              >
              <span v-else class="text-upperCase">{{getInitials(avatar)}}</span>
            </v-avatar>
          </template>
          <span>
            {{ displayName(avatar) }}
          </span>
        </v-tooltip>
      </div>
      <div
      v-if="params.value.length > 5"
      class="avatars-group__item number-col">
        <v-avatar>
          <span>+{{params.value.length - 5}}</span>
        </v-avatar>
      </div>
    </section>
  </span>
</template>

<script>
import Vue from 'vue';
import _ from 'lodash';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default Vue.extend({
  computed: {
    getSortedItems() {
      return this.params.value.slice(0, 5);
    },
  },
  methods: {
    getOptimizedS3ImageURL,
    profilePic(member) {
      const profilePic = _.get(member.user, 'profilePic');
      if (profilePic) {
        return profilePic;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/users.svg');
    },
    convert(user, type) {
      const name = _.get(user, type, '');
      return name ? name.slice(0, 1) : '';
    },
    getInitials(member) {
      const firstName = this.convert(member.user, 'firstName');
      const lastName = this.convert(member.user, 'lastName');
      return `${firstName} ${lastName}`;
    },
    displayName(member) {
      const firstName = _.get(member.user, 'firstName', '');
      const lastName = _.get(member.user, 'lastName', '');
      return `${firstName} ${lastName}`;
    },
    borderedClass(member) {
      const profilePic = _.get(member.user, 'profilePic');
      if (profilePic) {
        return 'border-white';
      }
      return 'border-dark';
    },
  },
});
</script>

<style lang="scss" scoped>
.number-col {
  margin-left: -40px;
  opacity: 1;
  font-size: 18px;
}
.addOpacity {
  opacity: 0.8;
}
  .avatars-group {
    .v-avatar{
      background-color: #fff;
      box-shadow: 0px 0px 0px 2px #fff inset;
      height: 40px !important;
      width: 40px !important;
      min-width: 40px !important;
      .text-upperCase {
        text-transform: uppercase;
      }
    }
    .v-avatar.border-white {
      border: 2px solid #fff;
    }
    .v-avatar.border-dark {
      border: 2px solid #565682;
    }
    &.stacked {
      display: flex;
      flex-direction: row;
      direction: ltr;
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      .avatars-group__item {
        margin-right: -8px;
      }
    }
  }
</style>
