<template>
  <div class="h-100">
    <grid-list
      :headers="headers"
      :items="items"
      :fetching="fetching"
      @onModalOpen="$emit('onModalOpen')"
      :search="search"
      @rowClicked="(data) => $emit('rowClicked', data)"
    >
    </grid-list>
  </div>

</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { getOptimizedS3ImageURL } from '@/helpers/';
import organizationsIcon from '@/assets/svg/organisations.svg';
import GridList from './GridList';
import AvatarRender from './AvatarRender';
import BtnActions from './BtnActions';

export default {
  components: {
    GridList,
  },
  props: {
    organizations: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('user', ['userDetails']),
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    headers() {
      const $this = this;
      const items = [
        $this.organizationColumn(),
        $this.btnActionsColumn(),
      ];
      if ($this.$vuetify.breakpoint.smAndUp) {
        items.push($this.businessTypeColumn());
      }
      return items;
    },
    items() {
      return _.map(this.organizations, (organization) => ({
        id: organization.id,
        organization,
        name: organization.name,
        bannerImage: organization.bannerImage,
        team: organization.userAgencies,
        status: this.getStatus(organization.status),
        businessType: _.get(organization, 'businessType.name', null),
      }));
    },
  },

  methods: {
    getOptimizedS3ImageURL,
    getStatus(status) {
      switch (status) {
        case '0':
          return 'Inactive';
        case '2':
          return 'Archived';
        default:
          return 'Active';
      }
    },
    openModal(val, type) {
      this.$emit('onModalOpen', val, type);
    },
    organizationColumn() {
      return {
        headerName: 'organization',
        headerClass: 'name-column',
        field: 'name',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap justify-start',
        width: 150,
        cellRenderer: (params) => `<div class="d-flex align-center">
        <div class="d-flex" style="padding-right: 21px;">
          <div style="width: 40px; height: 40px;">
            <img src=${getOptimizedS3ImageURL(params.data.bannerImage || organizationsIcon, { width: 250, height: 250 })} style="width: 100%; height: 100%; object-fit: contain;"/>
          </div>
        </div>
        <span class="text-purple"
        style="font-weight: 400;
          font-size: 16px;
          line-height: 22px;">${params.value}</span></div>`,
      };
    },
    businessTypeColumn() {
      return {
        headerName: 'Business Vertical',
        field: 'businessType',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap justify-start',
        width: 100,
      };
    },
    teamColumn() {
      return {
        headerName: 'team',
        field: 'team',
        cellStyle: { textAlign: 'left' },
        width: 160,
        height: 200,
        cellClass: 'notranslate text-wrap',
        cellRendererFramework: AvatarRender,
      };
    },
    statusColumn() {
      return {
        field: 'status',
        sortable: true,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
        filter: true,
        cellClass: 'notranslate text-wrap',
        width: 50,
      };
    },
    btnActionsColumn() {
      const $this = this;
      return {
        headerName: '',
        cellClass: 'justify-end',
        field: 'id',
        width: 20,
        cellRendererFramework: BtnActions,
        cellRendererParams: {
          role: this.role,
          type: 'organization',
          clicked(params, type) {
            $this.openModal(params.value, type);
            return false;
          },
        },
      };
    },
  },
};
</script>

<style scoped>

</style>
