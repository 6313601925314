<template>
    <div>
      <label :for="id" class="align-center h-100">
        <slot v-if="!preview"/>
        <FilePreview v-else :preview="preview" v-bind="$attrs">
          <template v-slot:default={filePreview}>
            <slot
              name="preview"
              v-bind="$attrs"
              :filePreview="filePreview"
            >
            </slot>
          </template>
        </FilePreview>
        <v-file-input
          class="input-field"
          :accept="acceptedFiles"
          :class="[$attrs.mode, inputStatus]"
          :append-icon="appendedIcon"
          :prepend-icon="prependedIcon"
          @click:append="appendIconCallback"
          @click:prepend="prependIconCallback"
          v-bind="$attrs"
          v-on="$listeners"
          :id="id"
          ref="uploadButton"
          @click.native="$refs.uploadButton.$el.querySelector('input').value = ''"
        ></v-file-input>
      </label>
    </div>
</template>

<script>
import FilePreview from '@/components/common/FilePreview';

export default {
  name: 'Upload',
  model: {
    prop: 'file',
    event: 'change',
  },
  props: {
    preview: {
      type: Boolean,
      default() {
        return false;
      },
    },
    id: {
      type: String,
      default: 'uploadFile',
    },
    label: {
      type: String,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIconCallback: {
      type: Function,
      default: () => {},
    },
    prependIconCallback: {
      type: Function,
      default: () => {},
    },
    acceptedFiles: {
      type: String,
      default: null,
    },
  },
  components: {
    FilePreview,
  },
  computed: {
    appendedIcon() {
      if (this.appendIcon) {
        return this.appendIcon;
      }
      if (this.status === 'error') {
        return 'mdi-alert-circle-outline';
      }
      return null;
    },
    prependedIcon() {
      if (this.prependIcon) {
        return this.prependIcon;
      }
      return null;
    },
    inputStatus() {
      return `${this.status}State`;
    },
  },
  data() {
    return ({

    });
  },
};
</script>

.<style lang="scss" scoped>
  .input-field.justImage {
    display: none;
  }
</style>
